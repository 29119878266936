:root {
  --color1: #fbbd00;
  --color2: #fbb700;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  text-decoration: none !important;
}

th a,
td a,
.black-text,
.black-text *:not(.btn) {
  color: #000000 !important;
}

.p-lg-2 {
  padding: 2rem;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.p-h {
  padding: 0.5rem !important;
}

.m-2 {
  margin: 0.2rem !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.ml-h {
  margin-left: 0.5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem;
}

.text-end {
  text-align: right;
}

.bb-1 {
  border-bottom: 1px solid #e3e3e3;
}

.bt-1 {
  border-top: 1px solid #e3e3e3;
}

.bl-1 {
  border-left: 1px solid #e3e3e3 !important;
}

.br-1 {
  border-right: 1px solid #e3e3e3;
}

.bb-0 {
  border-bottom: 0 !important;
}

.bl-0 {
  border-left: 0;
}

.bg-color {
  /* background: -webkit-linear-gradient(left, #539b00, #4caf50);
  background: -moz-linear-gradient(left, #539b00, #4caf50);
  background: linear-gradient(to right, #539b00, #4caf50); */
  padding-bottom: 20px;
}

.white-text,
.white-text * {
  color: #ffffff !important;
}

.op-h {
  opacity: 0.6;
}

.mt-0 {
  margin-top: 0 !important;
}

.h-100 {
  height: 100%;
}

.card {
  background-color: #ffffff;
  border-radius: 4px !important;
  box-shadow: none !important;
  border: 1px solid #e3e3e3;
  transition: all 0.3s ease !important;
  position: relative;
}

.leftMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  transition: all 0.5s ease;
}

.leftMenuInner {
  padding: 0 20px;
  width: 260px;
  height: 100%;
  background: #000000;
  position: absolute;
  top: 0;
  transition: all 0.5s ease;
}

.logo-img {
  cursor: pointer;
}

.topHeader {
  position: relative;
  margin-top: 50px;
  overflow: auto;
  min-height: 300px;
}

.topHeader .card {
  align-self: flex-start;
  width: 100%;
  background-color: transparent;
  border: 0;
  border-radius: 50px !important;
}

.topHeader .card a {
  color: #ffffff;
  padding: 0px;
  margin: 5px 0px;
}

.topHeader .card a img {
  margin-right: 10px;
}

.topHeader .card.active {
  transform-origin: bottom;
  z-index: 1;
  background: linear-gradient(
    to right,
    var(--color1),
    var(--color2)
  ) !important;
  border: 0;
}

.topHeader .card.active a,
.topHeader .card.active a * {
  color: #000000 !important;
}

.topHeader .card:hover {
  z-index: 2;
}

.topHeader .card a button {
  border-radius: 50px !important;
  display: flex;
  justify-content: start;
  transition: all 0.5s ease;
  padding: 6px 10px;
}

.topHeader .card a button:hover {
  background: rgb(255 255 255 / 12%);
}

.topHeader .card a span {
  font-size: 15px;
  font-weight: 500;
}

.topHeader .card a svg {
  font-size: 28px;
  margin-right: 10px;
}

.card a {
  display: flex;
  color: #000000;
}

.card a p {
  align-self: center;
  line-height: initial;
}

.card button {
  padding: 10px;
}

.content-sec {
  background-color: #f4f4f4;
  width: 100% !important;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 60px;
  position: relative;
}

.content-sec .card h2 {
  font-size: 32px;
  font-weight: 500;
  margin: 10px 0;
}

.content-sec .card h3 {
  font-size: 24px;
  font-weight: 600;
}

.content-sec .card span {
  font-size: 13px;
}

.content-sec .card button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content-sec .card button img {
  align-self: center;
}

.content-sec .card a {
  position: relative;
}

/* .content-sec .card a:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 0px solid transparent;
  border-top: 8px solid var(--color2);
  position: absolute;
  right: 0px;
  top: 0px;
} */

.form-sec {
  padding: 20px 20px 20px 300px;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.justify-content-between {
  justify-content: space-between;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-stretch {
  align-self: stretch;
}

.plan-btn {
  width: 90%;
  white-space: normal !important;
  margin-bottom: 15px !important;
}

.footer {
  position: fixed;
  bottom: 10px;
}

#customerForm #businessId + fieldset,
#customerForm #customerId + fieldset {
  display: none;
}

.visitorStatus {
  position: absolute;
  background: #666666;
  color: #ffffff;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 11px !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  min-width: 60px;
  text-align: center;
  transition: all 0.5s ease;
}

.visit-card .visitorStatus:after {
  content: "";
  height: 50px;
  width: 50px;
  position: absolute;
  top: -14px;
  left: 0;
  right: 0;
  margin: auto;
  border: 1px dashed #666666;
  border-radius: 50px;
  z-index: -1;
}

.visit-card[data-status="Delivered"] .visit-type,
.visit-card[data-status="Delivered"] .visitorStatus {
  background-color: green;
}

.visit-card[data-status="Delivered"] .visitorStatus:after {
  border-color: green;
}

.visit-card[data-status="Open"] .visit-type,
.visit-card[data-status="Open"] .visitorStatus {
  background-color: orange;
}

.visit-card[data-status="Open"] .visitorStatus:after {
  border-color: orange;
}

.section-title {
  background: #ffffff;
  border-bottom: 1px solid #f4f4f4;
  padding: 10px;
  border-radius: 6px;
}

.datePicker,
.form-control.date-picker {
  border: 0 !important;
  border-left: 1px solid #e3e3e3 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  width: 174px;
  height: 100% !important;
  padding: 0px 10px !important;
}

.visit-count {
  background: #000000;
  color: #fff !important;
  border-radius: 20px;
  padding: 2px 6px 2px 2px;
  margin: 0 10px !important;
  font-size: 14px !important;
}

.visit-count strong {
  font-weight: 500;
  position: relative;
  top: -2px;
}

.visit-count svg {
  position: relative;
  top: 2px;
}

.count-tag {
  transition: all 0.5s ease;
  background-color: #ffffff;
  color: #ff0000;
  padding: 3px 8px;
  line-height: 20px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
}

.customer-pic div,
.customer-pic svg {
  display: block;
  background-color: #f4f4f4;
  height: 44px;
  width: 44px;
  align-self: center;
  margin: auto;
  color: #cccccc;
  border-radius: 110px;
  overflow: hidden;
}

.customer-pic div {
  background-size: cover;
  background-position: center;
}

.customer-pic svg {
  font-size: 16px;
}

.card-item {
  transition: all 0.5s ease;
}

.card-item:hover {
  transform: scale(1.01);
}

.card-item .btn {
  padding: 3px 10px;
}

table thead th {
  font-weight: 600 !important;
}

p.model-year {
  font-size: 12px !important;
  color: #666666;
}

.title-sec {
  position: fixed;
  top: 0;
  left: 260px;
  width: calc(100% - 260px) !important;
  z-index: 99;
  background-color: #ffffff;
  border-bottom: 1px solid #e3e3e3;
  justify-content: space-between;
}

.title-sec h2 {
  padding: 15px;
}

.title-sec.title-xs {
  left: 540px;
  width: calc(100% - 540px) !important;
}

.stat-icon {
  background: #f4f4f4;
  border-radius: 4px;
  padding: 10px;
  margin-right: 6px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.flew-wrap div {
  flex-wrap: wrap;
}

/* Tooltip */
.MuiTooltip-tooltip {
  font-size: 14px !important;
}

.MuiTooltip-popper {
  top: -5px !important;
}

.customer-chart div {
  align-self: center;
  margin: 20px 0;
}

.jobcard-chart div {
  align-self: center;
  margin-top: -60px;
}

.go-icon {
  vertical-align: middle;
  margin-left: 4px;
  position: relative;
  top: -1px;
}

.search-form {
  display: flex;
}

/* Toggle Menu */
.MuiMenu-paper {
  box-shadow: 3px 3px #ccc !important;
  border: 1px solid #ccc;
  /* right: 50px;
  left: initial !important; */
  padding: 0px;
}

.MuiMenu-paper ul {
  padding: 0;
}

.MuiMenu-paper ul li {
  padding: 8px 15px !important;
  border-bottom: 1px solid #ccc;
  margin: 0 !important;
}

.MuiMenu-paper ul li svg {
  margin-right: 6px;
  font-size: 20px !important;
}

.visit-type {
  background-color: #666666;
  color: #ffffff;
  font-size: 11px !important;
  display: inline;
  border-radius: 4px;
  align-self: center;
  padding: 1px 8px;
}

.visit-table {
  position: relative;
  font-size: 14px;
  height: calc(100vh - 200px);
}

.visit-table tr {
  /* cursor: pointer; */
  transition: all 0.5s ease;
  position: relative;
  overflow: hidden;
}

.visit-table tr:hover {
  background-color: #fbfbfb;
}

.visit-table tr:hover .visitorStatus {
  transform: translate(-50%, -50%) rotate(-15deg) scale(1.3);
}

.visit-table td {
  transition: all 0.5s ease;
  padding: 15px 16px;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.visit-table th {
  vertical-align: middle !important;
}

.visit-table th,
.visit-table td,
.visit-table td p {
  font-size: 14px;
  vertical-align: top;
}

.visit-table td p * {
  vertical-align: middle;
}

.visit-table td img {
  align-self: flex-start;
}

.visit-btn:after {
    background: #e3e3e340;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 20px;
    border-radius: 50px;
    z-index: 0;
    transform: scale(0) translate(-50%, -50%);
    transform-origin: top left;
    transition: all 0.5s ease;
}

.visit-btn:hover:after {
  transform: scale(1.2) translate(-50%, -50%);
  opacity: 1;
}

.visit-btn {
    padding: 5px 10px !important;
    top: -2px !important;
}

.visit-btn span {
  position: relative;
  z-index: 1;
}

.text-right,
.text-right input {
  text-align: right !important;
}

.more-btn {
  color: #666666 !important;
  cursor: pointer;
  border-radius: 50px;
  width: 28px;
  height: 28px;
  text-align: center;
  margin: 0;
  transition: all 0.5s ease;
}

.more-btn:hover {
  background: #f4f4f4;
}

.more-btn svg {
  font-size: 24px !important;
}

#demo-simple-select {
  padding: 10px 14px;
}

.data-grid {
  box-shadow: none !important;
  border: 1px solid #cccccc !important;
  background-color: #ffffff;
}

.headerStyle * {
  font-weight: 600 !important;
}

.add-info input {
  padding: 10px;
}

.add-info {
  padding: 1px 0px 0px 6px !important;
  margin-right: -8px !important;
}

.remove-btn {
  min-width: auto !important;
}

.payment-card .btn {
  padding: 4px 4px;
}

.payment-card .btn svg {
  font-size: 18px !important;
}

.fade-menu {
  display: none;
}

.menuCheckbox:checked + .menuList {
  display: block;
}

.visit-table tr:hover .menuList {
  height: 30px;
  bottom: 1px;
}

.visit-table tr:hover td {
  padding-bottom: 45px;
}

.menuList {
  position: absolute;
  right: 6px;
  bottom: 0px;
  height: 0;
  background: #ffffff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0;
  transition: all 0.3s ease;
  overflow: hidden;
  box-shadow: 0 -4px 6px #f4f4f4;
}

.menuList ul {
  list-style: none;
  margin: 0;
}

.menuList ul li {
  padding: 5px 10px;
  cursor: pointer;
  border-right: 1px solid #cccccc;
  display: inline-block;
  font-size: 13px !important;
  font-weight: 600;
}

.menuList ul li:last-child {
  border-right: 0;
}

.menuList ul li:hover {
  background-color: #f4f4f4;
}

.menuList ul li svg {
  font-size: 14px !important;
  margin-right: 4px;
  top: 2px;
  position: relative;
}

.menuList ul li span {
  vertical-align: middle;
}

.closeMenu {
  width: 100%;
  padding: 0 0 10px 0;
  background-color: transparent !important;
  cursor: pointer;
}

.closeMenu svg {
  font-size: 24px !important;
  color: #ccc;
  transition: all 0.5s ease;
}

.closeMenu:hover svg {
  color: #666666;
}

.searchInput input {
  padding: 10px 15px;
}

.last-login {
  position: absolute;
  bottom: 0;
  left: 10px;
  padding: 10px;
  color: #ffffff;
  z-index: 1;
  width: 240px;
  min-height: inherit;
}

.prod-input button {
  display: inline-block !important;
  padding: 5px 6px !important;
}

/* Collapse Menu */
.collapseMenu {
  padding-left: 88px;
}

.collapseMenu .title-sec {
  left: 88px;
  width: calc(100% - 88px) !important;
}

.collapseMenu .topHeader .card a button span {
  display: none;
}

.collapseMenu .leftMenuInner {
  width: 88px;
}

.collapseMenu .leftMenuInner .logo-text,
.collapseMenu .leftMenuInner .last-login-datetime {
  display: none;
}

.collapseMenu .leftMenuInner .last-login {
  width: 76%;
}

/* cs-modal */
.cs-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 36%);
}

.css-modal {
  position: absolute;
  top: 48%;
  left: 50%;
  bottom: 20%;
  transform: translate(-50%, -50%);
  width: 85%;
  min-height: 560px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 36%);
}

.modal-form .modal-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 15px 15px;
  border-top: 1px solid #ccc;
}

.modal-form .modal-dialog-content {
  margin-bottom: 52px;
}

/* Data Grid Searchbar */
.searchbar {
  width: 100% !important;
  padding: 10px !important;
  border-radius: 2px;
}

h2.dialog-title {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
}

.ins-table tbody tr th,
.ins-table tbody tr td {
  padding-bottom: 0px;
  padding-top: 5px;
}

.icon-btn {
  cursor: pointer;
}

.number-input input {
  line-height: 42px;
  padding-right: 3px;
}

.invoice-table {
  height: calc(100vh - 220px);
  overflow: auto;
}

.disabled-form * {
  pointer-events: none;
}

.payment-filter {
  flex: 1;
  text-align: center;
  margin: 5px 0px 0 10px;
}

.mb-txt {
  font-size: 12px;
  line-height: 20px;
  display: none;
}

.visit-filter-sec {
  padding: 10px 15px;
}

.visit-filter-check {
  display: none !important;
  right: 52px !important;
  padding: 6px !important;
  z-index: 99;
}

.menu-check {
  display: none;
}

.branch-list {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
}

.branch-list:last-child {
  border-bottom: 0;
}

.stat-sec {
  /* background-color: #ffffff; */
}

.stat-sec .card button {
  padding: 25px;
}

.modal-form {
  z-index: 9999 !important;
}

#indexLoading {
  width: 100%;
  height: 99vh;
  display: flex;
  justify-content: center;
  text-align: center;
  position: absolute;
  left: 0;
  overflow: hidden;
}

#indexLoading p {
  align-self: center;
}

#indexLoading p img {
  width: 100px;
  margin: auto;
}

body .icon20 {
  font-size: 20px !important;
}

body .icon24 {
  font-size: 24px !important;
}

.expense-card[data-status="Active"] {
  cursor: pointer;
}

.expense-card[data-status="Void"] {
  background-color: #e3e3e3;
}

.logo-sec {
  justify-content: center;
  margin-top: 3rem;
}

.logo-sec img {
  width: 152px;
  align-self: center;
}

.logo-sec h1 {
  font-size: 28px !important;
  margin-left: 5px;
  position: relative;
  top: -4px;
}

.btn.menu-icon {
  display: none;
}

.payment-modal {
  width: 550px;
}

.payment-modal-sec h5 {
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
  font-weight: 400;
}

.payment-modal-sec h5 input {
  border: 0;
  font-weight: 700;
  width: 30%;
  word-wrap: break-word;
}

.css-payment-modal {
  position: absolute;
  top: 45%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 35%;
  min-height: 90px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 36%);
}

/* Temp fix */
.css-axafay-MuiDataGrid-virtualScroller {
  min-height: 150px;
}

.fixed-btn {
  position: fixed;
  bottom: 0;
  background: #ffffff;
  width: 100%;
  padding: 10px 20px;
  z-index: 9;
  left: 539px;
  width: calc(102% - 569px) !important;
  border-top: 1px solid #e3e3e3;
}

.invoice-btn-sec button {
  flex: auto;
}

.invoice-label {
  width: 160px;
  top: 2px;
  box-shadow: 0 3px 2px rgb(90 160 10 / 12%) !important;
}

button.price-btn {
  padding: 0 2px;
  background-color: #f4f4f4;
  min-width: auto;
  height: 40px;
  color: #000000;
  margin: 0 !important;
  border-radius: 4px !important;
}

.price-btn.btn-left {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.price-btn.btn-right {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.currency-symbol {
  position: absolute;
  padding: 0px 10px;
}

.discount-label {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

div.discount-label .MuiFormControl-fullWidth {
  padding: 0px !important;
}

.discount-label .MuiSelect-select {
  padding-left: 5px !important;
}

.discount-label fieldset {
  border: 0;
}

.discount-label svg {
  right: 0;
}

.discount-label .inputField {
  border-left: 1px solid rgba(0, 0, 0, 0.23);
}

.btn.discount-remove-btn {
  padding: 0px 5px;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  width: 26px;
}

.protect-box button {
  justify-content: start;
}

.lock-icon {
  position: absolute !important;
  right: 0;
  z-index: 1;
}

.emp-page .MuiInputLabel-shrink {
  background: #fff;
  padding-right: 6px !important;
}

.MuiDataGrid-footerContainer p {
  margin-bottom: 0;
}

.empty-filter-sec fieldset span {
  display: none;
}

.MuiInputLabel-shrink {
  color: rgba(0, 0, 0, 0.6) !important;
}

img[alt="Not Found"] {
  display: inline-block;
  font-family: Arial, sans-serif;
  font-weight: 300;
  line-height: 2;
  text-align: center;
  position: relative;
  min-height: 120px;
  cursor: pointer;
}

img[alt="Not Found"]:after {
  content: "";
  width: 100%;
  height: calc(100% + 10px);
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%);
  line-height: 130px;
  background: #fff url(images/no-image.jpg) no-repeat center;
  background-size: 100%;
}

.qty-field {
  width: 60px;
  height: 40px;
}

.qty-field input {
  padding: 5px;
  text-align: right;
  height: 30px;
}

.action-sec .MuiDataGrid-actionsCell {
  margin: auto;
}
